@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.outerContainer {
  @import "../App.scss";

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .carouselWrapper {
    max-width: 19%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .button {
      align-self: center;
      border-radius: 50%;
      background-color: transparent;
      padding: 0.25rem;
      width: 2.5rem;
      height: 2.5rem;
      & * {
        fill: white;
      }
    }
    .next {
      margin-top: -80px;
    }
    .slides {
      overflow: hidden;
      height: 570px;
      max-height: 720px;
      .thumbnailWrap {
        overflow: hidden;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        margin-top: -100px;

        &:nth-child(3) {
          display: none;
        }

        &::after,
        &::before {
          content: "";
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 130px;
          pointer-events: none;
        }

        &::before {
          top: 100px;
          z-index: 2;
          background: linear-gradient(
            0deg,
            hsla(0, 0%, 100%, 0) 0%,
            rgb(0, 0, 0) 100%
          );
        }

        &:after {
          bottom: 0px;
          background: linear-gradient(
            0deg,
            rgb(0, 0, 0) 0%,
            hsla(0, 0%, 100%, 0) 100%
          );
        }

        .imgWrap {
          position: absolute;
          padding: 0;
          margin: 0;
          opacity: 0.7;
          width: 100px !important;
          height: 100px !important;
          transition: all 0.4s ease, opacity 0.4s ease;
          border-radius: 50%;
          z-index: 1;
          overflow: hidden;
          margin-top: -1.5rem;
          & > span {
            width: 100%;
            height: 100%;
          }
          &::after {
            position: absolute;
            content: "";
            width: 3.5rem;
            height: 2px;
            background-color: white;
            top: 50%;
            transform: translateY(-50%);
            right: -4rem;
            z-index: 2222;
            transition: opacity 0.5s ease-in;
            opacity: 0;
          }
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }
        }
        .active {
          position: relative;
          z-index: 2;
          width: 130px !important;
          height: 130px !important;
          overflow: visible;
          opacity: 1;
          &::after {
            opacity: 1;
          }
        }
        .visible {
          margin: 0;
          opacity: 1 !important;
        }
      }
    }
  }
  .contentWrapper {
    max-width: 80%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;

    .contentWrap {
      padding-top: 115px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;
      & * {
        color: whitesmoke;
        margin: 0;
        padding: 0;
      }
      .skills {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;
        .skill {
          position: relative;
          & p {
            text-transform: capitalize;
            white-space: nowrap;
          }
          ::after {
            content: "";
            position: absolute;
            bottom: -0.5rem;
            left: 0;
            width: 75%;
            height: 2px;
            background-color: whitesmoke;
          }
        }
      }
      .name {
        // height: 30px;
      }
      .description {
        display: -webkit-box;
        height: 96px;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 1rem;
        & p {
          white-space: pre-line;
        }
      }
      .btnPrimary {
        width: fit-content;
      }
    }
    .imgWrap {
      & > span {
        width: 100%;
        height: 100%;
      }
      flex: 1;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  //
  // Media Queries MD
  //

  @include media-breakpoint-down(md) {
    flex-direction: column;
    .carouselWrapper {
      flex-direction: row;
      max-width: 75%;
      order: 2;

      &:nth-of-type(1) {
        & svg {
          transform: rotate(-90deg);
          margin: 0;
        }
      }

      &:nth-of-type(2) {
        & svg {
          transform: rotate(90deg);
        }
      }
      .button {
        & * {
        }
      }
      .next {
      }
      .slides {
        width: 100%;
        min-height: 130px;
        height: 100%;
        .thumbnailWrap {
          min-height: 130px;
          margin: 0;
          &:nth-child(3) {
          }

          &::after,
          &::before {
            width: 60px;
            height: 100%;
          }

          &::before {
            top: 0px;
            right: 0;
            z-index: 2;
            background: linear-gradient(
              90deg,
              hsla(0, 0%, 100%, 0) 0%,
              rgb(0, 0, 0) 100%
            );
          }

          &:after {
            bottom: unset;
            top: 0;
            left: 0;
            background: linear-gradient(
              90deg,
              rgb(0, 0, 0) 0%,
              hsla(0, 0%, 100%, 0) 100%
            );
          }

          .imgWrap {
            margin: 0;
            opacity: 0;
            &::after {
            }
            & img {
            }
          }
          .active {
            opacity: 1;
            &::after {
              display: none;
            }
          }
          .visible {
          }
        }
      }
    }
    .contentWrapper {
      order: 1;
      max-width: 100%;
      .contentWrap {
        width: 100%;
        padding-top: 0rem;
        padding-bottom: 2rem;
        order: 2;
        .name {
          height: 24px;
        }
        & * {
        }
        .skills {
          height: 24px;
          .skill {
            & p {
            }
            ::after {
            }
          }
        }
        .name {
        }
        .description {
          height: 40px;
          -webkit-line-clamp: 3;
          margin: 0;
          & p {
          }
        }
        .btnPrimary {
        }
      }
      .imgWrap {
        min-width: 200px;
        max-width: 300px;
        & img {
        }
      }
    }
  }

  //
  // Media Queries MD
  //

  @include media-breakpoint-down(sm) {
    .carouselWrapper {
      max-width: 100%;
      .button {
        & * {
        }
      }
      .next {
      }
      .slides {
        .thumbnailWrap {
          &:nth-child(3) {
          }

          &::after,
          &::before {
          }

          &::before {
          }

          &:after {
          }

          .imgWrap {
            &::after {
            }
            & img {
            }
          }
          .active {
            &::after {
            }
          }
          .visible {
          }
        }
      }
    }
    .contentWrapper {
      flex-direction: column;
      .contentWrap {
        align-items: center;
        order: 2;
        & * {
          text-align: center;
        }
        .skills {
          .skill {
            & p {
            }
            ::after {
            }
          }
        }
        .name {
        }
        .description {
          & p {
          }
        }
        .btnPrimary {
        }
      }
      .imgWrap {
        order: 1;
        & img {
        }
      }
    }
  }
}
