@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.left {
  & svg {
    position: absolute;
    // padding-bottom: 1rem;
    top: 1rem;
    left: 1rem;
  }
}

.termsWrap {
  @import "../../App.scss";
  position: relative;

  flex-direction: column;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    background-color: black;
    left: 60vw;
    top: 0;
    width: 50vw;
    height: 100%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .goBack {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 111111;
    padding-bottom: 2rem;
    & svg {
      background-color: whitesmoke;
      width: fit-content;
      padding: 0;
    }
  }
  .innerWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;

    .left {
      flex: 1 60%;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      padding: 3rem 1rem;
      gap: 2rem;
      overflow: hidden;
      max-height: 100%;
      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 1rem;
        overflow: scroll;
        height: 100%;
      }
      .head {
        text-align: center;
        padding: 2rem 0;
      }
      .itemWrap {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .title {
          font-size: 1.25rem;
          padding-bottom: 1rem;
          font-weight: 700;
          border-bottom: solid 2px rgba(black, 0.75);
        }
        .body {
          padding: 1rem;
          white-space: pre-line;
          font-weight: 300;
        }
      }
    }
    .right {
      flex: 1 40%;
      max-width: 40%;
      background-color: #000000;
      position: relative;
      padding: 3rem 1rem;
      display: flex;
      gap: 3rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1rem;
        top: 0rem;
        right: -0.5rem;
        background-color: #000000;
        z-index: 1;
      }
      & * {
        color: whitesmoke !important;
      }

      .logo {
        max-width: 200px;
        width: 100%;
        height: auto;
        z-index: 2;
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .content {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
        & * {
          text-align: center;
        }
        & a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .title {
          color: rgba(whitesmoke, 0.75) !important;
          margin-bottom: 0.5rem;
        }
      }

      .socialIcons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        & svg {
          background-color: rgba(whitesmoke, 0.9);
          border-radius: 50%;
          padding: 0.2rem;
          font-size: 1.5rem;
          & * {
            fill: black;
          }
          &:hover {
            background-color: rgba(whitesmoke, 1);
            transition: scale 0.2s ease-in-out;
            scale: 1.1;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    overflow-y: scroll;
    .goBack {
      & * {
        fill: whitesmoke;
        background-color: transparent !important;
      }
    }
    .innerWrap {
      flex-direction: column;
      height: unset;
      .left {
        flex: unset;
        max-width: 100%;
        order: 2;
        padding: 1rem;
        .content {
          .body {
            padding: 0;
          }
        }
      }
      .right {
        flex: unset;
        order: 1;
        max-width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
        justify-content: center;
        // display: none;
        .logo {
          max-width: 100px;
          margin-left: 1rem;
        }
        .content {
          // display: none;
          gap: 1rem;
          & div:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
