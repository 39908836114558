@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.slider {
  display: none;
  @import "../../App.scss";
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    background-color: black;
    left: -20vw;
    top: 0;
    width: 100vw;
    height: 100%;
  }

  .dotsWrap {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    z-index: 12;
    overflow-x: hidden; /* Use auto instead of scroll */
    white-space: nowrap;
    width: 92px;

    .dots {
      background-color: white;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      cursor: pointer;
      flex: 0 0 auto;

      &:hover {
        opacity: 0.7;
      }
    }

    .activeDot {
      transition: opacity 0.3s ease;
      opacity: 1 !important;
      cursor: default;
    }

    .visible {
      transition: opacity 0.3s ease;
      opacity: 0.25;
    }

    .notVisible {
      transition: opacity 0.3s ease;
      opacity: 0;
    }
  }

  .slide {
    height: 100%;
    font-size: 50px;
    background-color: rgba(#000000, 0.85);
    max-width: 1200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .close {
    position: absolute;
    z-index: 1;
    font-size: 30px;
    top: 2rem;
    left: 1.5rem;
    transform: translateY(-50%);
    fill: white !important;
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;
  }

  .arrow {
    position: absolute;
    z-index: 1;
    font-size: 30px;
    top: 50%;
    transform: translateY(-50%);
    fill: white !important;
    border-radius: 9999px;
    cursor: pointer;
    user-select: none;
  }

  .arrowLeft {
    left: 1.5rem;
  }

  .arrowRight {
    right: 1.5rem;
  }
}
