.adminWrap {
  display: flex;
  min-height: 100vh;
  .innerRow {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    .loginWrap {
      max-width: 400px;
      border: solid 1px rgba(#000000, 0.25);
      border-radius: 5px;
      padding: 1.5rem;
      margin-top: 2rem;
      height: min-content;
      .header {
        & h1 {
          text-align: center;
        }
      }
      .formWrap {
        margin-top: 1.5rem;
        .row {
          gap: 1.5rem;
          & > div {
            & button {
              margin-top: 1.5rem;
              width: 100%;
            }
          }
        }
      }
      .SignWrap {
        margin-top: 1.5rem;
        padding-top: 1rem;
        border-top: 1px solid rgba(#000000, 0.25);

        & p {
          text-align: center;
        }
      }
    }
  }
}
