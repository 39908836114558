@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.modalWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  min-height: 600px;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: transparent;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 100%;
    height: 100%;
    background: whitesmoke;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 0em;
      background: transparent;
    }
    & * {
      color: black;
    }
  }

  @include media-breakpoint-down(md) {
    max-width: 100vw;
    max-height: 100vh;
    top: 0;
    left: 0;
    transform: initial;
    .inner {
      & * {
        font-size: 1rem;
      }
    }
  }
}
