.mapContainer {
  width: 100%;
  height: 100%;
  min-height: 500px;

  .infoWindow {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.25rem;
    max-width: 250px;
    overflow: hidden;
    z-index: 2;
    & * {
      text-align: left;
      color: black !important;
      width: fit-content;
      max-width: 220px;
    }
    .button {
      background: transparent;
      border: 1px solid black;
      border-radius: 0;
      padding: 0.1rem 1rem;
      text-decoration: none;
      text-transform: capitalize;
    }
  }
}
