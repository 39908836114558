@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.homeWrapper {
  @import "../../App.scss";
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: url("../../../images/background-grain.jpg") repeat;
  position: relative;

  .sectionWrap {
    padding: 0 2rem;
    .wrap {
      padding: 0;
      position: relative;

      .title {
        position: absolute;
        top: 0;
        left: -4rem;
        color: whitesmoke;
        transform: rotate(90deg) translateX(40%) translateY(-50%);
        width: fit-content;
        z-index: 2;
        font-size: 1.25rem;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -2.4rem;
          height: 1px;
          width: 2rem;
          background-color: whitesmoke;
          transform: translateY(-50%);
        }
        @include media-breakpoint-down(md) {
          transform: none;
          left: 0;
          font-size: 1rem;
          top: 0rem;
          top: -2rem;
          display: none;
        }
      }
    }
    .spacer {
      height: 200px;
    }

    @include media-breakpoint-down(md) {
      .spacer {
        height: 150px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
      .spacer {
        height: 100px;
      }
    }
  }

  .heroBG {
    position: relative;
    background: url("../../../images/ABA-hero.jpg") no-repeat;
    background-position: 0% 90%;
    background-size: cover;
    position: relative;
    min-height: 100vh;
    width: 100%;
    padding-left: 3.5rem;
    z-index: 0;
    @include media-breakpoint-down(lg) {
      padding-left: 4rem;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.75);
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      z-index: 2;
      background: linear-gradient(
        0deg,
        rgb(0, 0, 0) 0%,
        hsla(0, 0%, 100%, 0) 100%
      );
      z-index: -1;
    }
    .heroRow {
      position: relative;
      min-height: 100vh;
      align-items: center;
      z-index: 2;
      .heroWrap {
        .contentWrap {
          z-index: 2;
          max-width: 330px;
          & h1 {
            color: whitesmoke;
          }
          & p {
            margin: 1.5rem 0;
            color: whitesmoke;
          }
        }
        .buttonWrap {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 2.5rem;
          width: fit-content;
          z-index: 2;
        }
      }
      .socialWrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        z-index: 2;
        max-height: 65vh;
        height: 100%;
        width: min-content;
        .line {
          background: whitesmoke;
          width: 2px;
          height: 100%;
          max-height: 225px;
        }
        .content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          gap: 1.5rem;
          & * {
            fill: black;
          }
          & svg {
            font-size: 1.5rem;
            width: 2rem;
            height: 2rem;
            padding: 0.3rem;
            background-color: whitesmoke;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .aboutWrap {
    position: relative;
    min-height: 530px;
    & * {
      color: whitesmoke;
    }
    .title {
      position: absolute;
      top: 0;
      left: -4.5rem;
      color: whitesmoke;
      transform: rotate(90deg) translateX(40%) translateY(-50%);
      width: fit-content;
      z-index: 2;
      font-size: 1.25rem;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -2.4rem;
        height: 1px;
        width: 2rem;
        background-color: whitesmoke;
        transform: translateY(-50%);
      }
      @include media-breakpoint-down(md) {
        transform: none;
        left: 0;
        top: -1.5rem;
        font-size: 1rem;
        display: none;
      }
    }
    .wrap {
      display: flex;
      .imgWrap {
        z-index: 1;
        max-width: 900px;
        max-height: 500px;
        height: 90%;
        width: 65%;
        & > span {
          width: 100%;
          height: 100%;
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .contentWrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 35%;
        z-index: 2;
        margin-left: auto;
        .head {
          padding: 2rem 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60%;
          background-color: black;
          & h2 {
            @include media-breakpoint-down(xl) {
              font-size: 2rem !important;
              white-space: nowrap;
            }
          }
        }
        .para {
          position: absolute;
          bottom: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          padding: 3rem;
          background-color: white;
          max-width: 560px;

          & p {
            color: black;
            margin: 0;
            max-width: 530px;
          }
        }
      }
    }
  }
  .instagramWrap {
    min-height: 500px;
    margin-right: -1rem;

    .wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      .contentWrap {
        flex: 30%;
        min-width: 30%;
        padding-right: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .logo {
          max-width: 140px;
          width: 100%;
          height: 100%;
          & > span {
            width: 100%;
            height: 100%;
          }
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .content {
          @include media-breakpoint-down(lg) {
            & h5 {
              font-size: 1.25rem;
            }
          }
          & h5 {
            margin-bottom: 0.5rem;
          }
          @include media-breakpoint-up(md) {
            margin-bottom: 0.5rem;
          }
        }
      }
      .carouselWrap {
        flex: 70%;
        max-width: 67vw;
        width: 100%;
        overflow: visible;
        gap: 1rem;

        .imgWrap {
          height: 400px;
          width: 100%;
          padding-right: 1rem;

          & > span {
            width: 100%;
            height: 100%;
          }

          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }

  .testimonialWrap {
    justify-content: center;
    padding-bottom: 2rem;
    .wrap {
      max-width: 600px;
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        .imgWrap {
          max-width: 100px;
          height: 100%;
          width: 100%;
          & img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .contentWrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          & p {
            text-align: center;
            padding: 0;
            margin: 0;
            color: whitesmoke;
            max-width: 570px;
          }
          .name {
            margin-bottom: 0.5rem;
          }
          .text {
            margin: 1rem 0;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }

  .contactWrap {
    position: relative;
    .wrap {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-end;
      .contact {
        width: 30%;
        min-width: 300px;
        .inner {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          max-width: 290px;
          padding-left: 1rem;
          & h5,
          & p {
            color: whitesmoke;
          }
          .btnPrimary {
            width: fit-content;
          }
        }
      }
      .map {
        position: relative;
        max-width: 69%;
        width: 100%;
      }
    }
  }

  //
  // MEDIA QUERIES -––– MD
  //

  @include media-breakpoint-down(md) {
    .heroBG {
      &::before {
      }
      &::after {
      }
      .socialWrap {
        .line {
        }
        .content {
          & * {
          }
          & svg {
          }
        }
      }
      .heroRow {
        .heroWrap {
          .contentWrap {
            & h1 {
            }
            & p {
            }
          }
          .buttonWrap {
          }
        }
      }
    }

    .aboutWrap {
      position: relative;
      & * {
      }
      .wrap {
        flex-direction: column;
        .imgWrap {
          position: absolute;
          top: 0;
          left: -2rem;
          width: 90%;
          max-height: 350px;

          & img {
          }
        }
        .contentWrap {
          width: 100%;
          z-index: 0;
          margin: 0;
          .head {
            display: none;
            & h2 {
            }
          }
          .para {
            position: static;
            max-width: unset;
            width: 100%;
            padding: 2rem;
            align-items: flex-start;
            padding-top: 14.5rem;
            margin-top: 9rem;
          }
        }
      }
    }
    .instagramWrap {
      & * {
        &::after,
        &::before {
          color: whitesmoke !important;
          font-size: 0.75rem;
        }
      }
      .wrap {
        flex-direction: column;
        gap: 2rem;
        .contentWrap {
          justify-content: center;
          align-items: center;
          padding: 0;
          gap: 0.5rem;
          .logo {
            & img {
            }
          }
          .content {
            & p {
              display: none;
            }
          }
        }
        .carouselWrap {
          max-width: 100%;
          .imgWrap {
            & img {
            }
          }
        }
      }
    }

    .testimonialWrap {
      .wrap {
        max-width: 500px;
        .inner {
          gap: 0.5rem;
          .imgWrap {
            & img {
            }
          }
          .contentWrap {
            & p {
            }
            .name {
            }
            .text {
            }
          }
        }
      }
    }

    .contactWrap {
      .wrap {
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        .contact {
          .inner {
            display: flex;
            align-items: center;
            & h5,
            & p {
              text-align: center;
            }
            .btnPrimary {
            }
          }
        }
        .map {
          max-width: 100%;
        }
      }
    }
  }

  //
  // MEDIA QUERIES -––– SM
  //

  @include media-breakpoint-down(sm) {
    .heroBG {
      padding: 0;
      &::before {
      }
      &::after {
      }

      .heroRow {
        .heroWrap {
          .contentWrap {
            & h1 {
            }
            & p {
            }
          }
          .buttonWrap {
            gap: 1rem;
            flex-wrap: nowrap;
          }
        }
        .socialWrap {
          top: unset;
          bottom: 10%;
          flex-direction: row;
          width: 100%;
          left: 50%;
          transform: translateX(-50%) translateY(0);
          justify-content: center;
          max-height: 40px;

          .line {
            width: 100%;
            max-width: 150px;
            height: 2px;
            max-height: unset;
          }
          .content {
            gap: 1rem;
            flex-direction: row;
            & * {
            }
            & svg {
            }
          }
        }
      }
    }

    .aboutWrap {
      position: relative;
      & * {
      }
      .wrap {
        .imgWrap {
          left: -1rem;
          width: 100%;
          max-height: 300px;
          & img {
            // height: 90%;
          }
        }
        .contentWrap {
          .head {
            & h2 {
            }
          }
          .para {
            padding-top: 11rem;
            & p {
            }
          }
        }
      }
    }
    .instagramWrap {
      & * {
      }
      .wrap {
        .contentWrap {
          .logo {
            & img {
            }
          }
          .content {
          }
        }
        .carouselWrap {
          .imgWrap {
            & img {
            }
          }
        }
      }
    }

    .testimonialWrap {
      .wrap {
        .inner {
          gap: 0.5rem;
          .imgWrap {
            & img {
            }
          }
          .contentWrap {
            & p {
            }
            .name {
            }
            .text {
            }
          }
        }
      }
    }

    .contactWrap {
      .wrap {
        .contact {
          .inner {
            & h5,
            & p {
            }
            .btnPrimary {
            }
          }
        }
        .map {
        }
      }
    }
  }

  //
  // MEDIA QUERIES -––– XS / SPARE
  //

  @include media-breakpoint-down(xs) {
    .heroBG {
      &::before {
      }
      &::after {
      }
      .socialWrap {
        .line {
        }
        .content {
          & * {
          }
          & svg {
          }
        }
      }
      .heroRow {
        .heroWrap {
          .contentWrap {
            & h1 {
            }
            & p {
            }
          }
          .buttonWrap {
          }
        }
      }
    }

    .aboutWrap {
      position: relative;
      & * {
      }
      .wrap {
        .imgWrap {
          & img {
          }
        }
        .contentWrap {
          .head {
            & h2 {
            }
          }
          .para {
            & p {
            }
          }
        }
      }
    }
    .instagramWrap {
      & * {
      }
      .wrap {
        .contentWrap {
          .logo {
            & img {
            }
          }
          .content {
          }
        }
        .carouselWrap {
          .imgWrap {
            & img {
            }
          }
        }
      }
    }

    .testimonialWrap {
      .wrap {
        .inner {
          gap: 0.5rem;
          .imgWrap {
            & img {
            }
          }
          .contentWrap {
            & p {
            }
            .name {
            }
            .text {
            }
          }
        }
      }
    }

    .contactWrap {
      .wrap {
        .contact {
          .inner {
            & h5,
            & p {
            }
            .btnPrimary {
            }
          }
        }
        .map {
        }
      }
    }
  }
}
