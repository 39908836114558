@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.left {
  & svg {
    position: absolute;
    // padding-bottom: 1rem;
    top: 1rem;
    left: 1rem;
  }
}

.artistWrap {
  margin: 0 auto !important;
  padding: 0;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 992px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1200px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1600px !important;
  }
}

.artistWrap {
  @import "../../App.scss";
  @import "./Carousel.module.scss";
  position: relative;

  display: flex;
  flex-direction: column;
  max-height: 100vh;

  &::before {
    content: "";
    position: absolute;
    background-color: black;
    left: 60vw;
    top: 0;
    width: 50vw;
    height: 100%;
  }

  .goBack {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 111111;
    padding-bottom: 2rem;
    & svg {
      background-color: transparent;
      width: fit-content;
      padding: 0;
      filter: invert(1);
      transform: rotate(180deg);
    }
  }
  .white {
    color: whitesmoke !important;
    fill: whitesmoke !important;
    & * {
      color: whitesmoke !important;
      fill: whitesmoke !important;
    }
  }
  .innerWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100vh;
    overflow: hidden;
    z-index: 100;

    .left,
    .right {
      overflow-y: auto;
      background-color: whitesmoke;
      scrollbar-width: none;
      height: 100%;
      scroll-behavior: auto;

      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar-thumb {
        width: 0em;
        background: transparent;
      }
    }

    .left {
      flex: 1 60%;
      max-width: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      gap: 2rem;
      max-height: 100%;
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0.5rem;
        justify-items: center;
        align-items: center;
        height: fit-content;
        @include media-breakpoint-down(lg) {
          grid-template-columns: repeat(2, 1fr);
        }
        .hexagon {
          flex: 1;
          height: 200px;
          width: 100%;
          overflow: hidden;
          & > span {
            width: 100%;
            height: 100%;
            background-size: contain !important;
            background-repeat: no-repeat;
            background-position: center;
          }
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
    .right {
      flex: 1 40%;
      max-width: 40%;
      background-color: #000000;
      position: relative;
      display: flex;
      gap: 3rem;
      flex-direction: column;

      // &::after {
      //   content: "";
      //   position: absolute;
      //   height: 100%;
      //   width: 1rem;
      //   top: 0rem;
      //   right: -0.5rem;
      //   background-color: #000000;
      //   z-index: 1;
      // }
      & * {
        color: whitesmoke !important;
      }

      .artist {
        display: flex;
        flex-direction: column;
        align-items: center;
        .imgWrapper {
          max-width: 450px;
          height: 100%;
          max-height: 450px;
          .imgWrap {
            & > span {
              width: 100%;
              height: 100%;
            }
            & img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .nameSkill {
            display: none;
          }
        }
        .contentWrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 2rem;
          padding-bottom: 2rem;
          gap: 1rem;
          max-width: 580px;
          & p {
            font-size: 1.2rem;
            @include media-breakpoint-down(lg) {
              font-size: 1rem;
            }
          }
          .name {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            & a {
              height: min-content;
              background-color: transparent;
              line-height: 1;
              font-size: 2rem;
            }
          }
          .skills {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;
            white-space: nowrap;
            .skill {
              position: relative;
              & p {
                text-transform: capitalize;
              }
              ::after {
                content: "";
                position: absolute;
                bottom: -0.5rem;
                left: 0;
                width: 75%;
                height: 2px;
                background-color: whitesmoke;
              }
            }
          }
          .description {
            margin-top: 1rem;
            white-space: pre-line;
          }
        }
      }
    }
  }

  //
  // Media Queries
  //

  @include media-breakpoint-down(md) {
    .innerWrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
      height: 100%;
      overflow-y: scroll;

      .left,
      .right {
        min-width: 200px;
        flex-basis: 1005;
        scrollbar-width: thin;
        overflow: unset;
      }

      .left {
        flex: unset;
        max-width: unset;
        width: 100%;
        height: 100%;
        order: 2;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 1rem;
        gap: 2rem;
        // overflow: initial;
        .grid {
          display: flex;
          grid-gap: 0.5rem;
          justify-items: center;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          height: 100%;
          .hexagon {
            flex: 1;
            height: 200px;
            width: 100%;
            min-width: 200px;
            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
      .right {
        flex: unset;
        max-width: unset;
        background-color: #000000;
        position: relative;
        display: flex;
        order: 1;
        padding: 1rem;
        gap: 3rem;

        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 1rem;
          top: 0rem;
          right: -0.5rem;
          background-color: #000000;
          z-index: 1;
        }
        & * {
          color: whitesmoke !important;
        }

        .artist {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: center;
          gap: 1rem;
          .imgWrapper {
            max-width: 45%;
            max-height: 100%;
            min-width: 300px;
            .imgWrap {
              & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }

            .nameSkill {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 1rem;
              margin-bottom: 1rem;

              .name {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                & a {
                  height: min-content;
                  background-color: transparent;
                  line-height: 1;
                  font-size: 0.9rem;
                }
              }
              .skills {
                display: flex;
                flex-direction: row;
                gap: 2rem;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 1rem;
                .skill {
                  position: relative;
                  & p {
                    text-transform: capitalize;
                    white-space: nowrap;
                  }
                  ::after {
                    content: "";
                    position: absolute;
                    bottom: -0.5rem;
                    left: 0;
                    width: 75%;
                    height: 2px;
                    background-color: whitesmoke;
                  }
                }
              }
            }
          }
          .contentWrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0rem;
            padding-bottom: 1rem;
            flex: 1;
            min-width: 325px;
            .name {
              display: none;
            }
            .skills {
              display: none;
            }
            .description {
              white-space: pre-line;
              @include media-breakpoint-down(sm) {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
