@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.hideNavbar {
  transform: translateY(-100px) !important;
  transition: transform 0.3s ease-in;
  @include media-breakpoint-down(md) {
    transform: translateY(-70px) !important;
  }
}

.activeBackground {
  // background: url("../../../images/background-grain.jpg") repeat !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 0) 100%
  );
  position: fixed !important;
  transform: translateY(0px) !important;
  transition: transform 0.3s ease-in;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.navbarWrapper {
  @import "../../App.scss";
  background-color: transparent;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 100;
  transform: translateY(0px);
  transition: transform 0.3s ease-in;

  .sectionWrap {
    padding: 0 3.5rem;

    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
    }
  }

  .row {
    .wrap {
      // padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      .logoWrap {
        max-height: 100px;
        height: 100%;
        padding: 1rem 0;
        cursor: pointer;
        @include media-breakpoint-down(md) {
          max-height: 70px;
        }
        & img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .linksWrap {
        display: flex;
        display: none;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        & a {
          text-transform: uppercase;
          text-decoration: none;
          color: rgba(whitesmoke, 0.9);
          font-size: 1.5rem;

          &:hover {
            color: rgba(whitesmoke, 1);
          }
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    height: 70px;
  }
}
