@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.left {
  // & svg {
  //   position: absolute;
  //   // padding-bottom: 1rem;
  //   top: 1rem;
  //   left: 1rem;
  // }
}

.contactWrap {
  @import "../../App.scss";
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    background-color: black;
    left: 60vw;
    top: 0;
    width: 50vw;
    height: 100%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .goBack {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 111111;
    padding-bottom: 2rem;
    & svg {
      background-color: transparent;
      width: fit-content;
      padding: 0;
      filter: invert(1);
    }
  }
  .close {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 111111;
    padding-bottom: 2rem;
    & svg {
      background-color: white;
      border-radius: 50%;
      border: 1px white solid;
      width: fit-content;
      padding: 0;
      filter: invert(1);
    }
  }
  .innerWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;

    .left {
      overflow: auto;
      flex: 1 60%;
      max-width: 60%;
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem 2rem 1rem;
      gap: 2rem;
      height: fit-content;
      max-height: 100%;
      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 1rem;
        .links {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1rem;
          & a {
            // text-decoration: none;
            text-transform: capitalize;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        & br {
          margin-bottom: 0.5rem;
        }
      }
      .formRow {
        .chooseForm {
          margin-bottom: 3rem;
        }
        .formWrap {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          height: 100%;
          padding-top: 1rem;
          width: 100%;

          .inputs {
            height: 100%;
            & > div {
              margin-bottom: 1rem;
              & textarea {
                // height: 50px;
                overflow: scroll;
                scrollbar-width: none;
                &::-webkit-scrollbar,
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-thumb {
                  width: 0em;
                  background: transparent;
                }
              }
            }
          }

          .btnPrimaryInverted {
            width: fit-content;
            &:disabled {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .right {
      flex: 1 40%;
      max-width: 40%;
      background-color: #000000;
      position: relative;
      padding: 3rem 1rem;
      display: flex;
      gap: 3rem;
      flex-direction: column;
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 1rem;
        top: 0rem;
        right: -0.5rem;
        background-color: #000000;
        z-index: 1;
      }
      & * {
        color: whitesmoke !important;
      }

      .logo {
        max-width: 200px;
        width: 100%;
        height: auto;
        z-index: 2;
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .content {
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
        & * {
          text-align: center;
        }
        & a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .title {
          color: rgba(whitesmoke, 0.75) !important;
          margin-bottom: 0.5rem;
        }
      }

      .socialIcons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        & svg {
          background-color: rgba(whitesmoke, 0.9);
          border-radius: 50%;
          padding: 0.2rem;
          font-size: 1.5rem;
          & * {
            fill: black;
          }
          &:hover {
            background-color: rgba(whitesmoke, 1);
            transition: scale 0.2s ease-in-out;
            scale: 1.1;
          }
        }
      }
    }
  }

  //
  // Media Queries MD
  //
  @include media-breakpoint-down(md) {
    // .goBack {
    //   & * {
    //     fill: whitesmoke;
    //   }
    // }
    .innerWrap {
      flex-direction: column;
      flex-wrap: nowrap;
      height: unset;
      .left {
        flex: unset;
        max-width: 100%;
        order: 2;
        padding: 1rem;
        padding-bottom: 2rem;
        .formRow {
          .formWrap {
            .inputs {
              max-height: 100%;
            }
          }
        }
      }
      .right {
        flex: unset;
        order: 1;
        max-width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        padding: 1rem;
        justify-content: center;
        // display: none;
        .logo {
          max-width: 100px;
          margin-left: 1rem;
        }
        .content {
          // display: none;
          gap: 1rem;
          & div:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
